<template>
  <div class="page">
    <div class="c-emailsent c-emailsent_h-800">
      <div class="c-emailsent__body c-emailsent__body_top-208">
        <img src = "@/assets/images/email-exclamation mark.svg" alt="email-check">
        <h2 class="c-emailsent__title"><span class="c-emailsent__title_black">Confirmation Email</span> Expired</h2>
        <p class="c-emailsent__content c-emailsent__content-whitelistexp">The link has expired (30 minutes validity), please request for a new confirmation email in your Sparrow account’s pending {{pageType}} page.</p>
        <p class="c-emailsent__content c-emailsent__content-whitelistexp">Please check your spam folder if you did not receive any confirmation email from Sparrow within the next few minutes upon request.</p>
        <a  class="c-emailsent__link" @click='routerRedirect("/signin")'>Return to Home >></a>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'WhitelistExpired',
  props:{
    pageType:String,
  },
  components: {
  },
  data() {
    return {
    }
  },
}
</script>
